@import "./index.scss";

.verify {
  max-width: 35% !important;
  box-shadow: 0 0 3px 0 $brand-black-shadow;
  background-color: $brand-white;
  padding: 40px 60px 15px 60px;
  margin: 0 auto 0;
  text-align: center;
  text-size-adjust: 14px;

  & img {
    width: 100px;
  }

  & p.thanks {
    margin: 50px 0 60px;
  }

  & p.link {
    font-size: 12px;
    color: black;
  }
}
.link {
  cursor: pointer;

  a, :active{
    color: #016ff6;
    text-decoration: none;
  }
}

@media screen and (max-width: 767px){
  .verify{
    width: 100%;
    max-width: 100%;
  }
}