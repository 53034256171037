@import "../index.scss";
$form-background-color: #f8f9fa;
$text-color: #1e1e1e;

.organisation-select {
  max-width: 35% !important;
  min-width: 250px;
  background-color: $form-background-color;
  padding: 40px 60px !important;
  margin: 0 auto 0;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  
  img[alt="FlexWhere"] {
    width: 93px;
    height: 93px;
    margin-bottom: 20px;
  }

  p {
    font-size: 15px;
  }

  .organisations {
    margin: 32px 0;

    button {
      width: 100%;
      border-width: 2px !important;
      border-color: #016ff6;
      color: black;

      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }

  .back-btn {
    float: left;
    color: #b6b6b6;
  }
}
