@import "../index.scss";
$form-background-color: #f8f9fa;
$text-color: #1e1e1e;
.invite-user {
  max-width: 35% !important;
  min-width: 250px;
  background-color: $form-background-color;
  padding: 80px 60px !important;
  margin: 0 auto 0;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  img[alt="FlexWhere"] {
    width: 93px;
    height: 93px;
    margin-bottom: 20px;
  }
  div.heading {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.09;
    margin-bottom: 20px;
  }
  div.description {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  .MuiFormControl-fullWidth {
    margin-bottom: 20px;
    .MuiFilledInput-root{
      background: var(--fw-white);
    }
    .MuiFilledInput-input{
      padding: 13px;
    }
    .MuiFormHelperText-root.Mui-error{
      text-align: left;
    }
  }
  .float-right {
    float: right;
  }
  .submit {
    width: 120px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6;
    text-transform: none;
  }
}
