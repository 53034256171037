.check {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        width: 1.8rem;
    }

    &.cancel {
        background-color: #ff2c6d;
    }

    &.extend {
        background-color: #6cd310;
    }
}

.MuiButton-text {
    text-transform: none !important;
}