$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1600px;

// Color variables
$brand-black-shadow: rgba(0, 0, 0, 0.3);
$brand-black: #000000;
$brand-white-smoke: whitesmoke;
$brand-white: #ffffff;
$brand-border: #b1b0af;
$brand-blue: #016ff6;

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.1ms linear;
  font-size: 14px;
  line-height: 1.6;

  @media screen and (min-width: $screen-sm) {
    font-size: 16px;
  }

  @media screen and (min-width: $screen-lg) {
    font-size: 18px;
  }

  @media screen and (min-width: $screen-xl) {
    font-size: 20px;
  }
}
:root {
  --fw-white: #fff;
  --fw-yellow: #ffbe0d;
  --fw-blue: #016ff6;
  --fw-black: #000000;
  --fw-light-black: #8c8e90;
  --fw-table-header: #f4f4f8;
}
#root, body{
  min-width: 100%;
  min-height: 100vh;
}
/* Center the window */
.MuiContainer-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center {
  max-width: 300px;
  margin: 0 auto;
}
.text-center {
  text-align: center;
}

.flw-logo {
  width: 100px;
  height: 100px;
}
#root{
  background: url('../public/images/bg-0.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}